

export const dashboardPagesMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Kontrol Paneli',
		path: '/',
		icon: 'Dashboard',
		subMenu: null,
	},
	lojistigoClub: {
		id: 'lojistigoClub',
		text: 'Girişim Kulüp',
		path: '/club',
		icon: 'AccountBalanceWallet',
	},
};
export const createOrderMenu = {
	createOrder: {
		id: 'createOrder',
		text: 'Gönderi Olustur',
		path: 'create-order',
		icon: 'AddToPhotos',
		subMenu: null,
	},
};

export const adminPagesMenu = {
	priceDefination: {
		id: 'PriceDefinationPage',
		text: 'Fiyat Tanımlama',
		path: 'price-defination',
		icon: 'AccountBalanceWallet',
	},
	carrierDefination: {
		id: 'CarrierDefinationPage',
		text: 'Taşıyıcı Listesi',
		path: 'carrier-defination',
		icon: 'FactCheck',
	},
	CountryZoneList: {
		id: 'CountryZoneListPage',
		text: 'Ülke Bölge Tanımlama',
		path: 'country-zones',
		icon: 'Public',
	},
	userList: {
		id: 'UserListPage',
		text: 'Kullanıcı Listesi',
		path: 'users',
		icon: 'Person',
	},	
	addUserBalanceWithEft: {
		id: 'addUserBalanceWithEft',
		text: 'Eft ile Bakiye Ekleme',
		path: 'users/add-balance-with-eft',
		icon: 'Money',
	},
	announcement: {
		id: 'announcements',
		text: 'Duyurular',
		path: 'announcements',
		icon: 'Campaign',
	},
	userBalances: {
		id: 'userBalances',
		text: 'Kullanıcı Bakiyeleri',
		path: 'user-balances',
		icon: 'AccountBalanceWallet',
	},
};

export const moderatorPagesMenu = {
	accepshipment: {
		id: 'acceptshipment',
		text: 'Gönderi Kabul',
		path: 'accept-shipments',
		icon: 'LocalShipping',
	},
	earlyTraceNumbers: {
		id: 'earlyTraceNumbers',
		text: 'Erken Takip Numarası Talepleri',
		path: 'orders/3',
		icon: 'Grading',
	},
}

export const userPagesMenu = {
	listPages: {
		id: 'listPages',
		text: 'Gönderiler',
		path: 'orders',
		icon: 'Dvr',
		subMenu: {
			listBoxed: {
				id: 'listBoxed',
				text: 'Gönderiler',
				path: 'orders',
				icon: 'Reorder',
			},
			approvedOrders: {
				id: 'approvedOrders',
				text: 'Onaylı Gönderiler',
				path: 'orders/1',
				icon: 'PlaylistAddCheck',
			},
			entegrationOrders: {
				id: 'entegrationOrders',
				text: 'Pazaryeri Gönderileri',
				path: 'orders/2',
				icon: 'Grading',
			},
		},
	},
	pricingTable: {
		id: 'pricingTable',
		text: 'Fiyat Hesaplama',
		path: 'calculate-price',
		icon: 'Calculate',
	},
	balancePage: {
		id: 'balancePage',
		text: 'Bakiye / Para Yükle',
		path: 'balance/add',
		icon: 'AccountBalanceWallet',
	},
	integrationsPage: {
		id: 'EntegrationsPage',
		text: 'Entegrasyonlar',
		path: 'integrations',
		icon: 'Settings',
	},
	courierList: {
		id: 'courierList',
		text: 'Kurye Taleplerim',
		path: 'courier/list',
		icon: 'LocalShipping',
	},
	etgbForms: {
		id: 'etgbForms',
		text: 'Mikro İhracat / Etgb',
		path: 'etgb-forms',
		icon: 'Assignment',
	},
	b2bList: {
		id: 'b2b',
		text: 'B2b Taşıma Teklifi Al',
		path: 'b2b/list',
		icon: 'ShoppingCart',
	},
	invoiceEntegrations: {
		id: 'invoiceEntegrations',
		text: 'Fatura Entegrasyonları',
		path: '#',//'invoice-entegrations',
		icon: 'Receipt',
	},
	support: {
		id: 'support',
		text: 'Destek',
		path: 'support',
		icon: 'Forum',
	},
};

export const landingPagesMenu = {
	balanceFailure: {
		id: 'balangeFail',
		text: 'Balance Fail',
		path: 'balance/fail',
	},
	balanceSuccess: {
		id: 'balanceSuccess',
		text: 'Balance Fail',
		path: 'balance/success',
	},
	barcode: {
		id: 'barcode',
		text: 'Barkod',
		path: 'get-barcode',
		icon: 'Accessible',
	},
	downloadBarcode: {
		id: 'downloadBarcode',
		text: 'Barkod Indir',
		path: 'download-barcode',
		icon: 'Accessible',
	},
	invoice: {
		id: 'invoice',
		text: 'Label',
		path: 'get-label',
		icon: 'Accessible',
	},
	integrationComplated: {
		id: 'integrationComplated',
		text: 'Entegrasyon Tamamlandı',
		path: 'integrations/complated',
		icon: 'CheckCircle',
	},
	balanceList: {
		id: 'balanceList',
		text: 'Bakiye Listesi',
		path: 'balance/list',
		icon: 'AccountBalance',
	},
	courierCreate : {
		id: 'courierCreate',
		text: 'Kurye Talep Et',
		path: 'courier/create',
		icon: 'LocalShipping',
	},
	b2bCreate : {
		id: 'b2bCreate',
		text: 'B2b Taşıma Teklifi Oluştur',
		path: 'b2b/create',
		icon: 'LocalShipping',
	},
	integrationsListPage: {
		id: 'integrationsListPage',
		text: 'Entegrasyonlar',
		path: 'integrations/list',
		icon: 'Settings',
	},
};

export const authMenu = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Giriş Yap',
		path: 'login',
		icon: 'Login',
	},
	autoLogin: {
		id: 'auto-login',
		text: 'Giriş Yap',
		path: 'auto-login',
		icon: 'Login',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'sign-up',
		icon: 'PersonAdd',
	},
	forgotPassword : {
		id : 'forgotPassword',
		text : 'Şifremi Unuttum',
		path : 'forgot-password',
		icon : '',
	},
	resetPassword : {
		id : 'resetPassword',
		text : 'Şifremi Sıfırla',
		path : 'reset-password',
		icon : '',
	},
	page404: {
		id: 'Page404',
		text: '404 Page',
		path: '404',
		icon: 'ReportGmailerrorred',
	},
};
