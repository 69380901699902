import * as Yup from 'yup';

export const RegisterValidations = Yup.object().shape({
	email: Yup.string()
		.email('Lütfen geçerli bir mail adresi giriniz.')
		.required('Lütfen mail adresinizi giriniz.'),
	password: Yup.string()
		.required('Lütfen şifrenizi giriniz.')
		.min(8, 'Şifreniz en az 8 karakter olmalıdır.')
		.matches(/[A-Z]/, 'Şifreniz en az bir büyük harf içermelidir.')
		.matches(/[!@#$%^&*(),.?":{}|<>]/, 'Şifreniz en az bir özel karakter içermelidir.'),
	confirmpassword: Yup.string()
		.oneOf([Yup.ref('password')], 'Şifreler eşleşmiyor')
		.required('Lütfen şifrenizi tekrar giriniz.'),
	firstname: Yup.string().required('Lütfen adınızı giriniz.'),
	lastname: Yup.string().required('Lütfen soyadınızı giriniz.'),
	phone_code: Yup.string().required('Lütfen telefon kodunu giriniz.'),
	phone_number: Yup.string()
		.required('Lütfen telefon numaranızı başında 0 olmadan giriniz.')
		.matches(
			/^\d{10}$/,
			'Telefon numaranız 10 haneli olmalıdır ve sadece rakamlardan oluşmalıdır.',
		),
	identity_no: Yup.string()
		.required('Lütfen kimlik numaranızı giriniz.')
		.min(11, 'Kimlik numarası 11 karakter olmalıdır.')
		.max(11, 'Kimlik numarası 11 karakter olmalıdır.')
		.matches(/^\d{11}$/, 'Kimlik numarası sadece rakamlardan oluşmalıdır.'),
	company_name: Yup.string().required('Lütfen şirket adınızı giriniz.'),
	adress: Yup.string().required('Lütfen adres bilgisini giriniz.'),
	post_code: Yup.string().required('Lütfen posta kodunu giriniz.'),
	tax_number: Yup.string().required('Lütfen vergi/tc numaranızı giriniz.'),
    city: Yup.string().required('Lütfen sehir bilgisini giriniz.'),
});
