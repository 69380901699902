import React, {
	FC,
	useCallback,
	useContext,
	useState,
	useEffect,
	useRef,
	KeyboardEvent,
} from 'react';
import { Link, useNavigate, Navigate } from 'react-router-dom';
import PropTypes, { object } from 'prop-types';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import Select, { SingleValue } from 'react-select';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import InputGroup, { InputGroupText } from '../../../components/bootstrap/forms/InputGroup';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import Spinner from '../../../components/bootstrap/Spinner';
import { parsedCities } from '../../../helpers/cities';
import Textarea from '../../../components/bootstrap/forms/Textarea';
import Icon from '../../../components/icon/Icon';
import Toasts from '../../../components/bootstrap/Toasts';
import { apiRequest } from '../../../common/other/apiRequest';
import showNotification from '../../../components/extras/showNotification';
import { useTour } from '@reactour/tour';
import { RegisterValidations } from './RegisterValidations';
import Checks from '../../../components/bootstrap/forms/Checks';
import AgreementModal from './AgreeMentModal';
import KvkkModal from './KvkkModal';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Hesabını Oluştur,</div>
				<div className='text-center h4 text-muted mb-5'>
					Lojistigonun eşsiz ayrıcalıkları için kayıt ol !
				</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Hoşgeldiniz,</div>
			<div className='text-center h4 text-muted mb-5'>
				Devam etmek için lütfen giriş yapınız!
			</div>
		</>
	);
};
LoginHeader.defaultProps = {
	isNewUser: false,
};

interface ILoginProps {
	isSignUp?: boolean;
}
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const { setIsOpen } = useTour();
	const { setUser } = useContext(AuthContext);
	const { setUserData } = useContext(AuthContext);
	const { setUserType } = useContext(AuthContext);
	const [isAgreed, setIsAgreed] = useState(false);
	const [isKvkkAgreed, setIsKvkkAgreed] = useState(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isKvkkModalOpen, setIsKvkkModalOpen] = useState(false);
	const [disableStaus, setDisableStatus] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
	const { darkModeStatus } = useDarkMode();
	const [margin, setMargin] = useState<number>(0);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSignUp, setisLoadingSignUp] = useState<boolean>(false);
	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [userName, setUserName] = useState();
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);
	// @ts-ignore
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Lütfen mail adresinizi giriniz.';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Parolanızı giriniz!';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			try {
				setDisableStatus(true);
				const response = await apiRequest({
					url: '/token',
					method: 'POST',
					body: {
						email: formik.values.loginUsername,
						password: formik.values.loginPassword,
					},
				});
				if (response.status === 200) {
					localStorage.setItem('token', response.data.token);
					localStorage.setItem('userId', response.data.userData.id);
					localStorage.setItem('ljs_asideStatus', 'true');
					if (setUser) {
						setUser('john');
						if (setUserData) {
							setUserData(response.data.userData);
						}
						if (setUserType) {
							setUserType(response.data.userData.type);
						}
					}
					const success = 'Başarılı';
					const successdet = 'success';
					showNotification(response.data.message, success, successdet, 2000);
					setTimeout(() => {
						setDisableStatus(false);
						handleOnClick();
						setIsOpen(true);
					}, 1000);
				} else {
					setDisableStatus(false);
					showNotification('Hata', response.data.message, 'danger');
				}
			} catch (error) {
				const typedError = error as any;
				console.log('typedError', error);
				showNotification(
					'Hata',
					typedError.response?.message || 'Bilinmeyen hata',
					'warning',
				);
				setIsLoading(false);
			}
		},
	});
	const signUpFormik = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: '',
			password: '',
			confirmpassword: '',
			firstname: '',
			lastname: '',
			phone_code: '+90',
			phone_number: '',
			identity_no: '',
			birth_date: '',
			company_name: '',
			adress: '',
			post_code: '',
			tax_number: '',
			city: 34,
			reference_code: '',
		},
		validationSchema: RegisterValidations,
		onSubmit: async (values) => {
			setisLoadingSignUp(true);
			if (!isAgreed || !isKvkkAgreed) {
				showNotification('Hata', 'Lütfen Sözleşmeleri Kabul Ediniz', 'warning');
				setisLoadingSignUp(false);
				return;
			}
			try {
				const response = await apiRequest({
					method: 'POST',
					url: '/register',
					body: {
						email: values.email,
						password: values.password,
						firstname: values.firstname,
						lastname: values.lastname,
						phone_number: values.phone_number,
						identity_no: values.identity_no,
						company_name: values.company_name,
						adress: values.adress,
						post_code: values.post_code,
						tax_number: values.tax_number,
						city: values.city,
						birth_date: values.birth_date,
						reference_code: values.reference_code,
					},
				});
				if (response.status === 200) {
					showNotification('Kayıt işlemi başarılı', 'Başarılı!', 'success');

					setTimeout(() => {
						window.location.reload();
					}, 2000);
				} else {
					const { errors, message, description } = response.data;
					const errorMsg = errors
						? Object.values(errors).flat().join(', ')
						: message || 'Bir hata oluştu';
					showNotification('Hata!', errorMsg, 'warning');
				}
				setisLoadingSignUp(false);
			} catch (error) {
				if (error instanceof Error) {
					const response = (error as any).response;
					showNotification('Hata!', response.data.message, 'warning');
				}
				setisLoadingSignUp(false);
			}
		},
	});

	const handleContinue = async () => {
		try {
			setIsLoading(true);
			setSignInPassword(false);
			const response = await apiRequest({
				url: '/checkUsers',
				method: 'POST',
				body: {
					email: formik.values.loginUsername,
				},
			});

			let success = '';
			let successdet = '';
			if (response.status === 200) {
				success = 'Başarılı';
				successdet = 'success';
			} else {
				success = 'Başarısız';
				successdet = 'danger';
			}
			showNotification(response.data.message, success, successdet);
			setTimeout(() => {
				if (response.status === 200) {
					setUserName(response.data.userName);
					setSignInPassword(true);
					const loginPasswordInput = document.getElementById(
						'loginPassword',
					) as HTMLInputElement | null;
					if (loginPasswordInput) {
						loginPasswordInput.focus(); // Focus on the loginUsername input field
					}
				} else {
					formik.setFieldError('loginUsername', response.data.message);
					setSignInPassword(false);
				}
				setIsLoading(false);
			}, 1000);
		} catch (error) {
			setIsLoading(false);
			formik.setFieldError('loginUsername', 'Sistemde kayıtlı kullanıcı bulunamadı');
			setSignInPassword(false);
		}
	};

	const handleEnterKey = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			handleContinue();
		}
	};

	const handleEnterKeyPassword = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === 'Enter') {
			formik.submitForm();
		}
	};

	return (
		<PageWrapper
			isProtected={false}
			title={singUpStatus ? 'Kayıt Ol' : 'Giriş Yap'}
			// className={classNames({
			// 	'bg-dark': !singUpStatus,
			// 	'bg-light': singUpStatus,
			// })}
			className='bg-light'>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xxl-6 col-lg-8 col-md-10 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-1'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}
										aria-label='Facit'>
										<Logo height={200} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Giriş Yap
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!singUpStatus}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setSignInPassword(false);
													setSingUpStatus(!singUpStatus);
												}}>
												Kayıt Ol
											</Button>
										</div>
									</div>
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								<form className='row g-4'>
									{singUpStatus ? (
										<>
											<div className='col-md-6'>
												<FormGroup id='email' isFloating label='Email'>
													<Input
														autoComplete='email'
														value={signUpFormik.values.email}
														isTouched={signUpFormik.touched.email}
														invalidFeedback={signUpFormik.errors.email}
														isValid={signUpFormik.isValid}
														onChange={signUpFormik.handleChange}
														onBlur={signUpFormik.handleBlur}
														onFocus={() => {
															signUpFormik.setErrors({
																...signUpFormik.errors,
																email: undefined,
															});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<div className='d-flex justify-content-between align-items-center'>
													<FormGroup
														id='password'
														isFloating
														label='Parola'
														className='col'>
														<Input
															type={
																passwordVisible
																	? 'text'
																	: 'password'
															}
															autoComplete='new-password'
															value={signUpFormik.values.password}
															isTouched={
																signUpFormik.touched.password
															}
															validFeedback='İyi Görünüyor.'
															invalidFeedback={
																signUpFormik.errors.password
															}
															isValid={signUpFormik.isValid}
															onChange={signUpFormik.handleChange}
															onBlur={signUpFormik.handleBlur}
															onFocus={() => {
																setMargin(15);
																signUpFormik.setErrors({
																	...signUpFormik.errors,
																	password: undefined,
																});
															}}
														/>
													</FormGroup>
													<Button
														style={{
															position: 'absolute',
															right: 45,
															marginBottom: margin,
															alignSelf: 'center',
														}}
														onClick={() => {
															setPasswordVisible(!passwordVisible);
														}}
														className='btn-only-icon'
														data-tour='dark-mode'
														aria-label='Toggle dark mode'>
														<Icon
															icon={
																passwordVisible ? 'eye-off' : 'eye'
															}
															color='dark'
															className='btn-icon'
														/>
													</Button>
												</div>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='confirmpassword'
													isFloating
													label='Şifre(Tekrar)'>
													<Input
														type='password'
														value={signUpFormik.values.confirmpassword}
														isTouched={
															signUpFormik.touched.confirmpassword
														}
														invalidFeedback={
															signUpFormik.errors.confirmpassword
														}
														isValid={signUpFormik.isValid}
														onChange={signUpFormik.handleChange}
														onBlur={signUpFormik.handleBlur}
														onFocus={() => {
															signUpFormik.setErrors({
																...signUpFormik.errors,
																confirmpassword: undefined,
															});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<Select
													id='city'
													placeholder='Lütfen İl Seçiniz.'
													options={parsedCities}
													defaultValue={parsedCities.find(
														(city) =>
															city.value === signUpFormik.values.city,
													)}
													onChange={(selectedOption: any) => {
														const selectedCity = selectedOption.value;
														signUpFormik.setFieldValue(
															'city',
															selectedCity,
														);
													}}
													onBlur={signUpFormik.handleBlur('city')}
													value={parsedCities.find(
														(city) =>
															city.value === signUpFormik.values.city,
													)}
													styles={{
														control: (provided) => ({
															...provided,
															backgroundColor: '#f8f9fa',
															borderColor: 'lightgray',
															borderWidth: '1.5px',
															borderRadius: '20px',
															height: '47.5px',
															boxShadow: '1px 1px 1px 1px lightgray',
														}),
														menu: (provided) => ({
															...provided,
															zIndex: 9999,
														}),
													}}
												/>
												{signUpFormik.errors.city && (
													<div style={{ color: 'red', fontSize: '12px' }}>
														{signUpFormik.errors.city}
													</div>
												)}
											</div>
											<div className='col-md-6'>
												<FormGroup id='firstname' isFloating label='Adınız'>
													<Input
														autoComplete='given-name'
														value={signUpFormik.values.firstname}
														isTouched={signUpFormik.touched.firstname}
														invalidFeedback={
															signUpFormik.errors.firstname
														}
														isValid={signUpFormik.isValid}
														onChange={signUpFormik.handleChange}
														onBlur={signUpFormik.handleBlur}
														onFocus={() => {
															signUpFormik.setErrors({
																firstname: undefined,
															});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='lastname'
													isFloating
													label='Soyadınız'>
													<Input
														autoComplete='family-name'
														value={signUpFormik.values.lastname}
														isTouched={signUpFormik.touched.lastname}
														invalidFeedback={
															signUpFormik.errors.lastname
														}
														isValid={signUpFormik.isValid}
														onChange={signUpFormik.handleChange}
														onBlur={signUpFormik.handleBlur}
														onFocus={() => {
															signUpFormik.setErrors({
																lastname: undefined,
															});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='birth_date'
													isFloating
													label='Doğum Tarihi'>
													<Input
														type='date'
														value={signUpFormik.values.birth_date}
														isTouched={signUpFormik.touched.birth_date}
														invalidFeedback={
															signUpFormik.errors.birth_date
														}
														isValid={signUpFormik.isValid}
														onChange={signUpFormik.handleChange}
														onBlur={signUpFormik.handleBlur}
														onFocus={() => {
															signUpFormik.setErrors({
																birth_date: undefined,
															});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='identity_no'
													isFloating
													label='Kimlik No'>
													<Input
														maxLength={11}
														value={signUpFormik.values.identity_no}
														isTouched={signUpFormik.touched.identity_no}
														invalidFeedback={
															signUpFormik.errors.identity_no
														}
														isValid={signUpFormik.isValid}
														onChange={signUpFormik.handleChange}
														onBlur={signUpFormik.handleBlur}
														onFocus={() => {
															signUpFormik.setErrors({
																identity_no: undefined,
															});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='phone_number'
													isFloating
													label='Telefon No'>
													<Input
														maxLength={10}
														autoComplete='tel'
														type='number'
														value={signUpFormik.values.phone_number}
														isTouched={
															signUpFormik.touched.phone_number
														}
														invalidFeedback={
															signUpFormik.errors.phone_number
														}
														isValid={signUpFormik.isValid}
														onChange={signUpFormik.handleChange}
														onBlur={signUpFormik.handleBlur}
														onFocus={() => {
															signUpFormik.setErrors({
																phone_number: undefined,
															});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='company_name'
													isFloating
													label='Şirket Adı'>
													<Input
														value={signUpFormik.values.company_name}
														isTouched={
															signUpFormik.touched.company_name
														}
														invalidFeedback={
															signUpFormik.errors.company_name
														}
														isValid={signUpFormik.isValid}
														onChange={signUpFormik.handleChange}
														onBlur={signUpFormik.handleBlur}
														onFocus={() => {
															signUpFormik.setErrors({
																company_name: undefined,
															});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='post_code'
													isFloating
													label='Posta Kodu'>
													<Input
														value={signUpFormik.values.post_code}
														isTouched={signUpFormik.touched.post_code}
														invalidFeedback={
															signUpFormik.errors.post_code
														}
														isValid={signUpFormik.isValid}
														onChange={signUpFormik.handleChange}
														onBlur={signUpFormik.handleBlur}
														onFocus={() => {
															signUpFormik.setErrors({
																post_code: undefined,
															});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='tax_number'
													isFloating
													label='Vergi/Tc Numarası'>
													<Input
														value={signUpFormik.values.tax_number}
														isTouched={signUpFormik.touched.tax_number}
														invalidFeedback={
															signUpFormik.errors.tax_number
														}
														isValid={signUpFormik.isValid}
														onChange={signUpFormik.handleChange}
														onBlur={signUpFormik.handleBlur}
														onFocus={() => {
															signUpFormik.setErrors({
																tax_number: undefined,
															});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-md-12'>
												<FormGroup id='adress' isFloating label='Adres'>
													<Textarea
														rows={3}
														value={signUpFormik.values.adress}
														isTouched={signUpFormik.touched.adress}
														invalidFeedback={signUpFormik.errors.adress}
														isValid={signUpFormik.isValid}
														onChange={signUpFormik.handleChange}
														onBlur={signUpFormik.handleBlur}
														onFocus={() => {
															signUpFormik.setErrors({
																adress: undefined,
															});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-md-6'>
												<FormGroup
													id='reference_code'
													isFloating
													label='Referans Kodu'>
													<Input
														value={signUpFormik.values.reference_code}
														isTouched={
															signUpFormik.touched.reference_code
														}
														invalidFeedback={
															signUpFormik.errors.reference_code
														}
														isValid={signUpFormik.isValid}
														onChange={signUpFormik.handleChange}
														onBlur={signUpFormik.handleBlur}
														onFocus={() => {
															signUpFormik.setErrors({
																tax_number: undefined,
															});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-md-12 '>
												<div className='d-flex '>
													<Checks
														checked={isAgreed}
														type='checkbox'
														onClick={() => setIsAgreed(!isAgreed)}
													/>
													<p>
														<a
															href='#'
															onClick={(e) => {
																e.preventDefault();
																setIsModalOpen(true);
															}}
															rel='noopener noreferrer'>
															Kullanıcı Sözleşmesi
														</a>
														'ni okudum ve onaylıyorum
													</p>
												</div>
												<div className='d-flex'>
													<Checks
														checked={isKvkkAgreed}
														type='checkbox'
														onClick={() =>
															setIsKvkkAgreed(!isKvkkAgreed)
														}
													/>
													<p>
														<a
															href='#'
															onClick={(e) => {
																e.preventDefault();
																setIsKvkkModalOpen(true);
															}}
															rel='noopener noreferrer'>
															Kvkk Onay Metni
														</a>
														'ni okudum ve onaylıyorum
													</p>
												</div>
											</div>
											<div className='col-12'>
												<div className='alert alert-warning' role='alert'>
													<Icon
														icon={'info'}
														color='dark'
														className='btn-icon'
													/>
													<span style={{ marginLeft: 10 }}>
														Whatsapp Destek Hattımız :
													</span>
													<a
														href='https://wa.me/905367333537'
														target='_blank'
														style={{
															marginLeft: 5,
															textDecoration: 'none',
															color: 'blue',
														}}>
														0536 733 35 37
														<Icon
															icon={'Phone'}
															color='info'
															className='btn-icon'
															style={{
																marginLeft: 5,
															}}
														/>
													</a>
												</div>
											</div>
											<div className='col-12'>
												<Button
													color='info'
													className='w-100 py-3'
													isDisable={isLoadingSignUp}
													onClick={signUpFormik.handleSubmit}>
													{isLoadingSignUp && (
														<Spinner isSmall inButton isGrow />
													)}
													Kayıt Ol
												</Button>
											</div>
										</>
									) : (
										<>
											<div className='col-12'>
												<FormGroup
													id='loginUsername'
													isFloating
													label='Email/Kullanıcı Adı'
													className={classNames({
														'd-none': signInPassword,
													})}>
													<Input
														autoComplete='username'
														value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={
															formik.errors.loginUsername
														}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onKeyDown={handleEnterKey}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
												{signInPassword && (
													<div className='text-center h4 mb-3 fw-bold'>
														Merhaba, {userName}.
													</div>
												)}
												<FormGroup
													id='loginPassword'
													isFloating
													label='Parola'
													className={classNames({
														'd-none d-flex ': !signInPassword,
													})}>
													<Input
														id='loginPassword'
														name='loginPassword'
														component='InputMask'
														type='password'
														autoComplete='current-password'
														value={formik.values.loginPassword}
														isTouched={formik.touched.loginPassword}
														invalidFeedback={
															formik.errors.loginPassword
														}
														validFeedback='İyi Görünüyor.'
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onKeyDown={handleEnterKeyPassword}
													/>
												</FormGroup>
											</div>
											<div className='col-12 text-center'>
												<a
													href='/forgot-password'
													target='_blank'
													className={classNames(
														'text-decoration-none me-3 text-info fw-bold',
														{
															'link-light': singUpStatus,
															'link-dark': !singUpStatus,
														},
													)}>
													Şifremi unuttum
												</a>
											</div>
											<div className='col-12'>
												<div className='alert alert-warning' role='alert'>
													<Icon
														icon={'info'}
														color='dark'
														className='btn-icon'
													/>
													<span style={{ marginLeft: 10 }}>
														Whatsapp Destek Hattımız :
													</span>
													<a
														href='https://wa.me/905367333537'
														target='_blank'
														style={{
															marginLeft: 5,
															textDecoration: 'none',
															color: 'blue',
														}}>
														0536 733 35 37
														<Icon
															icon={'Phone'}
															color='info'
															className='btn-icon'
															style={{
																marginLeft: 5,
															}}
														/>
													</a>
												</div>
											</div>
											<div className='col-12'>
												{!signInPassword ? (
													<Button
														color='info'
														className='w-100 py-3'
														isDisable={
															!formik.values.loginUsername ||
															isLoading
														}
														onClick={handleContinue}>
														{isLoading && (
															<Spinner isSmall inButton isGrow />
														)}
														Devam
													</Button>
												) : (
													<Button
														isDisable={disableStaus}
														color='warning'
														className='w-100 py-3'
														onClick={formik.handleSubmit}>
														{disableStaus && (
															<Spinner isSmall inButton isGrow />
														)}
														Giriş Yap
													</Button>
												)}
											</div>
										</>
									)}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Gizlilik Sözleşmesi
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Kullanım Koşulları
							</a>
						</div>
					</div>
				</div>
				<AgreementModal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)} />
				<KvkkModal
					isOpen={isKvkkModalOpen}
					toggle={() => setIsKvkkModalOpen(!isKvkkModalOpen)}
				/>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
